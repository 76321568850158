import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../components/Layout"
import { Link } from "gatsby"
import { CodeBlock } from "../commonComponents"


export default function TaggingAPIDocs() {
  const no_answer_json = {
    "tags": []
}
  const json = {
    "tags": [
        "Application Error", "Flight Log", "Resolved"
    ]
}

  return (
    <Layout
      keywords="Tagging API guide"
      title="Use IrisAgent's API to automatically tag and categorize support conversations"
      description="Documentation on how to use IrisAgent's API to tag and categorize support conversations based on their intents"
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/tagging-api-docs/"
        />
      </Helmet>
      {/* <!-- ======= Breadcrumbs ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Tagging API Guide</h2>
            <ol>
              <li>
                <Link to="/irisagent-docs/">Home</Link>
              </li>
              <li>Tagging API</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}
      <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "5%", margin: "10%" }}>
                    <h1>Tagging API Documentation</h1>
                    <br/>
                    <h2>
                    Introduction
                    </h2>
                    <br/>

                    IrisAgent's <a href="https://irisagent.com/support-operations/">Automatic Tagging Engine</a> can be used an API to get AI-powered tags corresponding to any conversation. It uses pre-built AI models to determine the right tag(s).
                    <br/>
                    <br/>
                    <h2>
                    API Guide
                    </h2>
                    <br/>
                    Below is a <span style={{"font-family": "Consolas"}}>curl</span> command to call our IrisGPT API:
                    <CodeBlock language="javascript" value={`
curl -X POST --location "https://frontend-api-server-v2.api.irisagent.com/v1/tag" \\
    -H "Content-Type: application/json" \\
    -H "Authorization: Bearer Replace_me_business_id" \\
    -H "X-Language-Iso-Code: en" \\
    -d '{
          "conversationId": "12345",
          "message": "Application will not access Flight Log\nWhen clicking on \"Flight Log\" the application keeps producing an ERROR message and will not allow access or Flight Log updating. Please help to fix this ASAP"
        }'
          `}>
</CodeBlock>
<br/>
The API inputs are as follows,
<br/><br/>
<table style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
    <tr>
        <th>Field</th>
        <th>Description</th>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>conversationId</span></td>
        <td>[Required] Unique ticket or chat id</td>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>message</span></td>
        <td>[Required] Message from the customer that needs to be auto-tagged</td>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>Replace_me_business_id</span></td>
        <td>[Required] This is the API key provided by the IrisAgent team</td>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>X-Language-Iso-Code</span></td>
        <td>[Optional] This optional header allows you to specify the desired language for IrisGPT's responses. If not provided, automatic language detection will be used</td>
    </tr>
</table>

<br/><br/>
A sample output of the API when matching tags are found:
<br/><br/>
<CodeBlock language="json" value={JSON.stringify(json, null, 2)}>
</CodeBlock>

<br/><br/>
A sample output of the API when no matching tags are found:
<br/><br/>
<CodeBlock language="json" value={JSON.stringify(no_answer_json, null, 2)}>
</CodeBlock>

                    </div>
                    </div>
                    </section>

</Layout>
  )
}